export default {

    setUser({ commit }, user) {
        commit('SET_USER', user);
    },

    setFacility({ commit }, departments) {
        commit('SET_FACILITY', departments);
    },

    setFiles({ commit }, files) {
        commit('SET_FILES', files);
    },

    setComment({ commit }, comment) {
        commit('SET_COMMENT', comment);
    },

    setProfile({ commit }, profile) {
        commit('SET_PROFILE', profile);
    },

    setProfiles({ commit }, profiles) {
        commit('SET_PROFILES', profiles);
    },

    setAccounts({ commit }, accounts) {
        commit('SET_ACCOUNTS', accounts);
    },

    setPaymentData({ commit }, data) {
        commit('SET_PAYMENT_DATA', data);
    },

    setLoyaltyDiscount({ commit }, discount) {
        commit('SET_LOYALTY_DISCOUNT', discount);
    },

};
