<template>
    <div class="ui-text-nav" @click="$emit('click', $event)">
        <div class="ui-text-nav__icon">
            <img src="~@/assets/icons/back-button.svg" />
        </div>
        <div class="ui-text-nav__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'textNav',

};

</script>

<style lang="scss" src="./textNav.scss" />
