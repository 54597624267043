<template>
    <button class="ui-btn"
    @click="$emit('click', $event)"
    :class="[getClasses(), {
        'ui-btn--disabled': disabled,
    }]"
    :style="{'background': `${background} !important`}">
        <div class="dynamic-background"></div>
        <span><slot></slot></span>
        <img v-if="defaultIcon && type === 'search'" src="@/assets/icons/next-circle.svg" />
    </button>
</template>

<script>

export default {
    
    name: 'btn',
    props: {

        type: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        defaultIcon: {
            type: Boolean,
            default: false,
        },

        tooltip: {
            type: String,
            default: '',
        },

        background: {
            type: String,
            default: '',
        },

    },
    methods: {

        getClasses(){

            switch (this.type){

            case 'add':
                return 'ui-btn--add';
            case 'next':
                return 'ui-btn--next';
            case 'login':
                return 'ui-btn--login';
            case 'search':
                return 'ui-btn--search';
            default:
                return 'ui-btn--default';

            }

        },

    },

};

</script>

<style lang="scss" src="./btn.scss" />
