export default {
    LIST_ITEM_TYPE: {
        COMING: 'COMING',
        PAST: 'PAST',
        NEW: 'NEW',
        TO_RESCHEDULE: 'TO_RESCHEDULE',
    },
    KIND_PRIVATE: 'Prywatna',
    STATE_TYPE: {
        RESERVED: 'Rezerwacja',
        TO_CONFIRMATION: 'Oczekuje na płatność',
        APPROVED_AND_PAID: 'Zaplanowana i opłacona',
    },
};
