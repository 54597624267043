import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import loader from './modules/loader';
import global from './modules/global';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        user,
        loader,
        global,
    },
});
