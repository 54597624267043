export default {

    methods: {
  
        app__getToken(name) {

            return new Promise((resolve, reject) => {

                const token = this.app__getLocalStorage(name || 'token');

                if (token) return resolve(token);
                return reject(new Error('No token found.'));

            });
    
        },
  
    },
  
};
