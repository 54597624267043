import Vue from 'vue';

import localStorage from './localStorage';
import token from './token';
import loader from './loader';
import mobile from './mobile';
import httpHandler from './httpHandler';

// Global mixins registration

Vue.mixin(localStorage);
Vue.mixin(token);
Vue.mixin(loader);
Vue.mixin(mobile);
Vue.mixin(httpHandler);
