<template>
    <div class="app-notifications">
        <div class="app-notifications__container">
            <div v-for="(item, index) in items" :key="index"
            class="notification"
            :class="{
                'notification--info': !item.type || item.type === 'info',
                'notification--success': item.type === 'success',
                'notification--error': item.type === 'error',
                'notification--warning': item.type === 'warning',
            }">
                <div class="notification__image">
                    <img v-if="!item.type || item.type === 'info'" src="~@/assets/icons/info-ico.svg" />
                    <img v-if="item.type && item.type === 'success'" src="~@/assets/icons/success-ico.svg" />
                    <img v-if="item.type && item.type === 'error'" src="~@/assets/icons/error-ico.svg" />
                    <img v-if="item.type && item.type === 'warning'" src="~@/assets/icons/alert-ico.svg" />
                </div>
                <div class="notification__content">
                    <h4 v-if="item.title">{{item.title}}</h4>
                    <div class="content" v-if="item.content" v-html="item.content"></div>
                </div>
                <div v-if="!item.preventClose" class="notification__controls">
                    <span @click="close(item._id)">&#10005;</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Notifications from './index'; // eslint-disable-line

export default {
    
    name: 'notifications',
    data(){
        return {

            items: [],

        };
    },
    methods: {

        generateRand(length){

            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i += 1) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            
            return result;

        },

        push(data){

            const notification = data;

            notification._id = this.generateRand(10);
            this.items.push(notification);

            if (!notification.constant){
                
                setTimeout(() => {
                    this.close(notification._id);
                }, notification.timeOut || 8000);

            }

        },

        clear(){
            this.items = [];
        },

        close(id){
            const notification = this.items.find(not => not._id === id);
            if (notification) this.items = this.items.filter(not => not._id !== id);
        },

    },
    beforeMount(){

        Notifications.EventBus.$on('push', (data = {}) => {
            this.push(data);
        });

        Notifications.EventBus.$on('closeAll', () => {
            this.clear();
        });

    },
    beforeDestroy(){
        this.clear();
    },

};

</script>

<style lang="scss" src="./notifications.scss" />
