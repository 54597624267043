import Vue from 'vue';

import btn from '@/components/ui/btn/btn.vue';
import textNav from '@/components/ui/textNav/textNav.vue';
import modal from './layout/modal';
import notifications from './layout/notifications';

const moment = require('moment'); // eslint-disable-line
const momentDurationFormatSetup = require("moment-duration-format");

momentDurationFormatSetup(moment);
require('moment/locale/pl'); // eslint-disable-line

// Global components registration
Vue.component('btn', btn);
Vue.component('textNav', textNav);
Vue.use(modal);
Vue.use(notifications);

// Global plugins registration
Vue.use(require('vue-moment'), {
    moment,
});
