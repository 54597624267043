export default {
    MONTH_NAMES: [
        'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec',
        'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień',
    ],
    DAY_NAMES: ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'],
    DATE_FORMAT: 'yyyy-mm-dd',
    HOUR_FORMAT: 'HH:MM',
    SERVER_DATE_FORMAT: 'yyyy-mm-dd',
    SERVER_HOUR_FORMAT: 'HH:MM:ss',
};
