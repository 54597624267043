export default Object.freeze([
    {
        path: '/register',
        name: 'Register',
        meta:{
            appStep: 4,
        },
        component: () => import(/* webpackChunkName: "Register" */ '@/views/Register/Register.vue'),
    },
    {
        path: '/profiles',
        name: 'Profiles',
        meta:{
            appStep: 5,
        },
        component: () => import(/* webpackChunkName: "Profiles" */ '@/views/Profiles/Profiles.vue'),
    },
]);
