export default {

    user: null,
    facility: null,
    profiles: null,
    remoteAccounts: null,
    selectedProfile: null,
    files: null,
    comment: '',
    paymentData: null,
    loyaltyDiscount: {},

};
