import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/patients_plugin/',
    linkExactActiveClass: 'is-active',
    routes,
});

router.beforeEach((to, from, next) => {

    const token = localStorage.getItem('token');

    if (to.matched.some(route => route.meta.secured)) {

        if (!token) {
            next({ name: "Login" });
            return;
        }

        next();
        return;

    }

    next();
});

export default router;
