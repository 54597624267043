export default {

    methods: {

        app__handleHttpFailureResponse(error) {

            if (error.response && error.response.status) {

                switch (error.response.status) {

                case 400:
                    this.$notifications.push({
                        content: 'Nie możesz wykonać wybranej akcji.',
                        type: 'error',
                    });
                    break;
                case 401:
                    this.$notifications.push({
                        content: 'Nie posiadasz odpowiednich uprawnień.',
                        type: 'error',
                    });
                    break;
                default:
                    this.$notifications.push({
                        content: 'Wystąpił błąd. Obsługa techniczna została automatycznie poinformowana o zaistniałej sytuacji.',
                        type: 'error',
                    });
                    
                }

            }

        },
        
    },

};
