export default {

    SET_REMOTE_APP_DATA(state, data) {
        state.remoteAppData = data;
    },

    SET_CITY(state, selectedCity) {
        state.selectedCity = selectedCity;
    },

    SET_DOCTOR(state, selectedDoctor) {
        state.selectedDoctor = selectedDoctor;
    },

    SET_SPECIALITY(state, selectedSpeciality) {
        state.selectedSpeciality = selectedSpeciality;
    },

    SET_VISIT_DATE(state, date) {
        state.visitDate = date;
    },

    SET_FREE_TERMS(state, terms) {
        state.freeTerms = terms;
    },

    SET_SELECTED_TERM(state, term) {
        state.selectedTerm = term;
    },

    SET_VISIT_TYPE(state, type) {
        state.visitType = type;
    },

    SET_VISIT_KIND(state, kind) {
        state.visitKind = kind;
    },

    SET_SELECTED_SERVICE(state, data) {
        state.selectedService = data || null;
    },

    SET_CITIES(state, cities) {
        state.cities = cities;
    },

    SET_DOCTORS(state, doctors) {
        state.doctors = doctors;
    },

    SET_SPECIALITIES(state, specialities) {
        state.specialities = specialities;
    },

};
