import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import config from './core/config';

// Config globals
Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API;

// Setup global variables
Vue.prototype.$http = axios;
axios.defaults.headers['Accept-Language'] = 'pl';
Vue.prototype.$config = config;

// Load global components
require('./components');

// Load global mixins
require('./mixins/global');

// Start Vue global instance
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
