<template>
        <div class="app-modal" :class="{'app-modal--active': visible}">
            <transition name="modal-box-slide" mode="out-in" @enter="addListener()">

                <div v-if="visible" class="modal-box" :class="[`modal-box--${name}`]">
                    <div class="modal-box__header" :class="{'modal-box__header--visible': title}">
                        <div v-if="title" class="header-left">
                            <p>{{title}}</p>
                        </div>
                        <div class="header-right">
                            <slot name="headerRight"></slot>
                            <img src="~@/assets/icons/close-white.svg"
                            @click="manualHide()" />
                        </div>
                    </div>
                    <div class="modal-box__body" :style="{'height': height ? `${height}vw` : 'initial'}">
                        <slot name="content"
                        :close="manualHide"
                        :confirm="confirm"
                        :data="data">
                        </slot>
                    </div>
                    <div v-if="footer" class="modal-box__footer">
                        <div class="footer-left">
                            <slot name="footerLeft" :close="manualHide" :confirm="confirm">
                                <btn type="cancel" :defaultIcon="false" @click="manualHide()">anuluj</btn>
                            </slot>
                        </div>
                        <div class="footer-right">
                            <slot name="footerRight" :close="manualHide" :confirm="confirm">
                                <btn :defaultIcon="false" type="accept" @click="steps ? action('confirm') : confirm()">potwierdź</btn>
                            </slot>
                        </div>
                    </div>
                </div>

            </transition>
        </div>
</template>

<script>

import Modal from './index'; // eslint-disable-line

export default {
    
    name: 'modal',
    props: {

        name: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: '',
        },

        backgroundClose: {
            type: Boolean,
            default: true,
        },

        footer: {
            type: Boolean,
            default: false,
        },

        steps: {
            type: Boolean,
            default: false,
        },

        height: {
            type: Number,
        },

    },
    data(){
        return {

            visible: false,
            modalBody: null,
            promiseResolve: null,
            data: null,

        };
    },
    methods: {

        show(){

            if (this.visible) return;
            this.visible = true;
            this.$emit('show');

        },

        manualHide(){
            this.clear();
            this.$emit('close');
        },

        confirm(data = this.data){

            if (this.promiseResolve) this.promiseResolve({ state: true, data });

            this.$emit('confirm', data);
            this.promiseResolve = null;
            this.data = null;
            this.clear();

        },

        action(event){

            this.$emit('action', event);

        },

        async addListener(){

            if (!this.backgroundClose) return;
            await this.$nextTick();
            this.modalBody = document.querySelector('.modal-box');
            document.body.addEventListener('click', this.close, true);

        },

        clear(){

            if (!this.visible) return;
            
            document.body.removeEventListener('click', this.close, true);

            this.visible = false;
            this.modalBody = null;

            if (this.promiseResolve) {
                this.promiseResolve({ state: false });
                this.promiseResolve = null;
                this.data = null;
            }

        },

        close(e){

            if (this.modalBody){

                const isClickInside = this.modalBody.contains(e.target);

                if (!isClickInside) this.manualHide();

            }

        },

    },
    beforeMount(){

        Modal.EventBus.$on('show', (name, data = null, resolve) => {

            if (this.name === name) {

                this.data = data;
                this.promiseResolve = resolve;
                this.show();

            } else {
                this.clear();
            }

        });

        Modal.EventBus.$on('close', name => {
            if (this.name === name) this.manualHide();
        });

        Modal.EventBus.$on('closeAll', () => {
            this.clear();
        });

    },
    beforeDestroy(){
        this.clear();
    },

};

</script>

<style lang="scss" src="./modal.scss" />
