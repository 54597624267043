import user from './user';

export default Object.freeze([

    ...user,

    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "Welcome" */ '@/views/Welcome/Welcome.vue'),
    },
    {
        path: '/visits',
        name: 'Visits',
        meta:{
            appStep: 1,
        },
        component: () => import(/* webpackChunkName: "Visits" */ '@/views/Visits/Visits.vue'),
    },
    {
        path: '/services',
        name: 'Services',
        meta:{
            appStep: 2,
        },
        component: () => import(/* webpackChunkName: "Services" */ '@/views/Services/Services.vue'),
    },
    {
        path: '/informations',
        name: 'Informations',
        meta:{
            appStep: 3,
        },
        component: () => import(/* webpackChunkName: "Informations" */ '@/views/Informations/Informations.vue'),
    },
    {
        path: '/finalization',
        name: 'Finalization',
        meta:{
            appStep: 6,
        },
        component: () => import(/* webpackChunkName: "Finalization" */ '@/views/Finalization/Finalization.vue'),
    },
    {
        path: '/payment_status',
        name: 'paymentStatus',
        meta:{
            appStep: 6,
        },
        component: () => import(/* webpackChunkName: "paymentStatus" */ '@/views/paymentStatus/paymentStatus.vue'),
    },

]);
