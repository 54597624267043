<template>
    <div class="app-loader">
        <div class="app-loader__loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loader",
};
</script>

<style lang="scss" scoped src="./loader.scss" />
