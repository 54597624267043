<template>
    <div id="app">
        <topBar v-if="$route.path !== '/'"/>
        <router-view v-if="!shutdown"/>
        <loader v-if="loader.length"/>
        <shutdown v-if="shutdown"/>
        <notifications />
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import loader from '@/components/layout/loader/loader.vue';
import topBar from '@/components/layout/topBar/topBar.vue';
import shutdown from '@/components/layout/shutdown/shutdown.vue';
import notifications from '@/components/layout/notifications/notifications.vue';

export default {

    name: 'App',
    components: {
        loader,
        topBar,
        shutdown,
        notifications,
    },
    data(){
        return {

            shutdown: false,

        };
    },
    watch: {

        $route(){
            if (Object.keys(this.$route.query).length && !this.remoteAppData) this.getRemotePluginData();
        },

    },
    methods: {

        ...mapActions('global', [
            'setRemoteAppData',
            'setCity',
            'setDoctor',
            'setSpeciality',
            'setVisitDate',
            'setVisitType',
            'setVisitKind',
            'setCities',
            'setDoctors',
            'setSpecialities',
        ]),
        ...mapActions('user', ['setFacility']),

        getRemotePluginData(){

            const { query } = this.$route;
            if (!query.access_token || !query.remote_app) return;

            const data = {
                accessToken: query.access_token,
                remoteApp: query.remote_app,
            };

            this.app__setLocalStorage('token', data);

            this.setRemoteAppData(data).then(() => {
                this.getFacility();
            });

        },

        getFacility(){

            this.app__loaderEnqueue(() => { // eslint-disable-line

                return new Promise(resolve => {

                    this.$http.get('facilities/', {
                        headers: {
                            'Content-Type': 'Application/json',
                        },
                        params: {
                            selected_facility_token: this.remoteAppData.accessToken,
                            remote_app: this.remoteAppData.remoteApp,
                        },
                    }).then(response => {

                        this.setFacility(response.data).then(() => this.initQuery());
                        resolve();

                    }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            // this.showAlert('error', 'Placówka wyłączyła możliwość zapisów online.');
                            this.shutdown = true;
                            return;
                        }

                        // this.app__handleHttpFailureResponse(error);
                        resolve();
                    });

                });

            });

        },

        async initQuery(){

            const load = this.app__setLoader();

            if (!this.cities.length) await this.getSugestions('city');
            if (!this.doctors.length) await this.getSugestions('person');
            if (!this.specialities.length) await this.getSugestions('specialty');

            this.preselectData();
            this.app__loaderDequeue(load);

        },

        getSugestions(type, query){

            return new Promise((resolve, reject) => {

                this.app__getToken().then(token => {

                    this.$http.get('visits/search_autosuggest/', {
                        headers: {
                            'Content-Type': 'Application/json',
                        },
                        params: {
                            selected_facility_token: token.accessToken,
                            remote_app: token.remoteApp,
                            search_type: type,
                            q: query,
                        },
                    }).then(response => {

                        if (type === 'city') this.setCities(response.data);
                        if (type === 'person') this.setDoctors(response.data);
                        if (type === 'specialty') this.setSpecialities(response.data);

                        resolve(response.data);

                    }).catch(error => {
                        // this.app__handleHttpFailureResponse(error);
                        reject(new Error(error));
                    });

                });

            });

        },

        async preselectData(){

            this.setPreselectedDoctor();
            this.setPreselectedCity();
            this.setPreselectedKind();
            this.setPreselectedType();
            this.setPreselectedSpeciality();

        },

        setPreselectedCity(){

            if (this.cities.length === 1){
                this.setCity(this.cities[0]);
                return;
            }

            if (this.cities.length > 1) this.setCity({ name: 'Dowolne' });

            const { query } = this.$route;

            if (!query.city || this.selectedCity) return;
            const match = this.cities.filter(city => city.name === query.city);
            if (match.length === 1) this.setCity(match[0]);

        },

        setPreselectedDoctor(){

            if (this.doctors.length === 1){
                this.setDoctor(this.doctors[0]);
                return;
            }

            const { query } = this.$route;

            if (!query.doctor || this.selectedDoctor) return;
            const match = this.doctors.filter(doctor => `${doctor.first_name.toLowerCase()}${doctor.last_name.toLowerCase()}`.replaceAll(' ', '') === query.doctor);
            if (match.length === 1) this.setDoctor(match[0]);

        },

        setPreselectedSpeciality(){

            if (this.specialities.length === 1 && !this.selectedDoctor){
                this.setSpeciality(this.specialities[0]);
                return;
            }

            const { query } = this.$route;

            if (!query.speciality || this.selectedDoctor) return;
            const match = this.specialities.filter(speciality => `${speciality.name.toLowerCase()}` === query.speciality);
            if (match.length === 1) this.setSpeciality(match[0]);

        },

        setPreselectedKind(){

            const { query } = this.$route;
            const kinds = this.facility.patient_visit_kinds_options || [];
            let selected;

            if (kinds.length){

                if (query.visitkind) {
                    const match = kinds.filter(kind => `${kind.value.toLowerCase()}` === query.visitkind.toLowerCase());
                    if (match.length === 1) [selected] = match;
                }

                if (!selected) [selected] = kinds;

            }

            if (selected?.value === 'NFZ'){
                this.setVisitKind('NFZ');
                return;
            }

            this.setVisitKind('Prywatna');

        },

        setPreselectedType(){

            const { query } = this.$route;
            const types = this.facility.patient_visit_performances_options || [];
            let selected;

            if (types.length){

                if (query.evisit) {
                    const bool = (query.evisit.toLowerCase() === 'true');
                    const match = types.filter(type => type.value === bool);

                    if (match.length === 1) [selected] = match;
                }

                if (!selected) [selected] = types;

            }

            if (selected?.value){
                this.setVisitType('evisit');
                return;
            }

            this.setVisitType('stationary');

        },

    },
    computed: {
        ...mapState('loader', ['loader']),
        ...mapState('user', ['facility']),
        ...mapState('global', [
            'remoteAppData',
            'cities',
            'doctors',
            'specialities',
            'selectedCity',
            'selectedDoctor',
            'selectedSpeciality',
            'visitType',
            'visitKind',
        ]),
    },
    created(){
        this.getRemotePluginData();
    },

};

</script>

<style lang="scss" src="@/globalStyles/main.scss" />
