<template>
    <div class="app-shutdown">
        <div class="app-shutdown__container">
            <img src="~@/assets/images/logo/logo-white.svg" />
            <h1>Przepraszamy</h1>
            <p>Placówka wyłączyła możliwość zapisów online.</p>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'shutdown',

};

</script>

<style lang="scss" src="./shutdown.scss" />
