import defaultState from './defaultState';
import actions from './actions';
import mutations from './mutations';

const state = { ...defaultState };

export default {
    strict: process.env.NODE_ENV !== 'production',
    namespaced: true,
    state,
    actions,
    mutations,
};
