import notifications from './notifications.vue';

const Notifications = {

    install(Vue) { 

        this.EventBus = new Vue();
        Vue.component('notifications', notifications);

        Vue.prototype.$notifications = { // eslint-disable-line

            push(data) {
                Notifications.EventBus.$emit('push', data);
            },

            closeAll() {
                Notifications.EventBus.$emit('closeAll');
            },

        };

    },

};

export default Notifications;
