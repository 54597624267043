<template>
    <div class="app-top-bar">
        <div class="app-top-bar__left">
            <p v-if="$route.name !== 'paymentStatus'">UMÓW WIZYTĘ</p>
            <h1>{{facility ? facility.name : ''}}</h1>
        </div>
        <div class="app-top-bar__right">
            <ul class="app-steps">
                <li v-for="(step, index) in 6" :key="index" :class="{'active': activeStep(step)}">
                    <span v-if="step > 1">-</span>{{ step }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    
    name: 'topBar',
    methods: {

        activeStep(step){
            return step <= this.$route?.meta?.appStep;
        },

    },
    computed: {
        ...mapState('user', ['facility']),
    },

};

</script>

<style lang="scss" src="./topBar.scss" />
