export default {

    loaderEnqueue({ commit }, loaderId) {
        commit('LOADER_ENQUEUE', loaderId);
    },

    loaderDequeue({ commit }, loaderId) {
        commit('LOADER_DEQUEUE', loaderId);
    },
    
    clearLoader({ commit }) {
        commit('CLEAR_LOADER');
    },

};
