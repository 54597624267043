import modal from './modal.vue';

const Modal = {

    install(Vue) { 

        this.EventBus = new Vue();
        Vue.component('modal', modal);

        Vue.prototype.$modal = { // eslint-disable-line

            show(name, data) {

                return new Promise(resolve => {

                    Modal.EventBus.$emit('show', name, data, resolve);

                });

            },

            tryToValidate(name) {
                Modal.EventBus.$emit('tryToValidate', name);
            },

            close(name) {
                Modal.EventBus.$emit('close', name);
            },

            closeAll() {
                Modal.EventBus.$emit('closeAll');
            },

        };

    },

};

export default Modal;
